import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  characterAssets: [],
  weaponAssets: [],
  artifactAssets: []
};

const gameAssetSlice = createSlice({
  name: 'gameAssets',
  initialState,
  reducers: {
    setCharacterAssets: (state, action) => {
      const characterAssets = action.payload;
      const characterAssetMapping = characterAssets.reduce(
        (mapping, asset) => ({ ...mapping, [asset.name]: asset }),
        {}
      );
      return {
        ...state,
        characterAssets: characterAssetMapping
      };
    },
    setWeaponArtiAssets: (state, action) => {
      const weaponArtiAssets = action.payload;
      const weaponAssetMapping = weaponArtiAssets.weaponAssets;
      const artifactAssetMapping = weaponArtiAssets.artifactAssets;
      return {
        ...state,
        weaponAssets: weaponAssetMapping,
        artifactAssets: artifactAssetMapping
      };
    }
  }
});

export const {
  reducer: gameAssetReducer,
  actions: gameAssetActions
} = gameAssetSlice;

export default gameAssetReducer;
