import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import Auth from './components/Auth';
import {
  createStyles,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ScrollReset from './components/ScrollReset';
import { createTheme } from './theme';
import Routes from './Routes';
import * as dotenv from 'dotenv';
import GoogleAnalytics from './components/GoogleAnalytics';
import CookiesNotification from './components/CookiesNotification';
import { useDispatch, useSelector } from 'react-redux';
import { gameAssetActions } from './slices/gameAssetSlice';
import CharacterAssetAPI from './api/game-assets/character-asset';
import WeaponArtiAssetAPI from './api/game-assets/weapon-arti-asset';
import { THEMES } from './constants';

dotenv.config();

const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%'
      },
      body: {
        height: '100%',
        width: '100%'
      },
      '#root': {
        height: '100%',
        width: '100%'
      }
    }
  })
);

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    CharacterAssetAPI.getAllCharacterAssets().then(data => {
      dispatch(gameAssetActions.setCharacterAssets(data.characterAssets));
    });
    WeaponArtiAssetAPI.getAllCharacterAssets().then(data => {
      dispatch(gameAssetActions.setWeaponArtiAssets(data));
    });
  }, []);

  useStyles();
  const themeMode = useSelector(state => state.theme.theme);
  const theme = createTheme({
    theme: themeMode ?? THEMES.LIGHT
  });

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider maxSnack={1}>
            <Router history={history}>
              <Auth>
                <ScrollReset />
                <GoogleAnalytics />
                <CookiesNotification />
                <Routes />
              </Auth>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
