import { combineReducers } from 'redux';
import accountReducer from './accountSlice';
import gameAssetReducer from './gameAssetSlice';
import themeReducer from './themeSlice';

const rootReducer = combineReducers({
  account: accountReducer,
  gameAssets: gameAssetReducer,
  theme: themeReducer
});

export default rootReducer;
