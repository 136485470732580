import { createSlice } from '@reduxjs/toolkit';
import { THEMES } from '../constants';

const initialState = {
  theme: localStorage.getItem('theme') ?? THEMES.LIGHT
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: (state, action) => {
      const theme = action.payload;
      localStorage.setItem('theme', theme);
      return {
        ...state,
        theme
      };
    }
  }
});

export const { reducer: themeReducer, actions: themeActions } = themeSlice;

export default themeReducer;
